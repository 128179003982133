<template>
  <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getPDF"
    :loading="loading">
    <v-icon left>mdi-printer</v-icon> Rel. Orçamento (Só Orçado SEM ADITIVO)
  </v-btn>
</template>
<script>
import api from "../../../http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsFunc from "../../../service/utilsFunc";
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png";
import ona from "../../../assets/ONA_Nivel_3.png";
import UtilsPDF from '../../../service/utilsPDF';

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Orcamento",
  components: {
  },
  data: () => withCRUDUtils({
    prorrogacao: [],
    budget: [],
    pacote: [],
    taxa: [],
    equipamentos: [],
    matmed: [],
    loading: false,
    totalFee: null,
    totalMatMed: null,
    totalEquipment: null
  }),
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
    username: String
  },
  methods: {
    async getPDF() {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await Promise.all([
          this.getOrcamento(),
          this.getProrrogacao(),
          this.getPacote(),
          this.getTaxa(),
          this.getEquipamentos(),
          this.getmatMed(),
        ]);
        await this.generatePDF()
        this.loading = false;
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados (L-56):", error);
        this.loading = false;
      }
    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl;
      const res = await fetch(httpsurl);
      const buffer = await res.blob();
      const url = URL.createObjectURL(buffer);
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF (L-76):", error);
      }

      const data = this.budget[0];

      const lineHeight = 6
      const xFixedPosition = 10;
      let initialYPosition = 45
      let yPosition = 45

      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setLineWidth(0.5); // largura da borda
      doc.roundedRect(5, 40, 200, 25, 3, 3, "S");
      // doc.roundedRect(x, y, largura, altura, border-radius, border-radius, 'preenchimento');

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Orçamento nº ${data?.id}`, 105, 23, { align: "center" });

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      const marginRight = 5; // Defina a margem desejada do lado direito (5px)
      const pageWidth = doc.internal.pageSize.getWidth(); // Obtenha a largura total da página
      const textWidth = doc.getTextWidth(`Gerado por: ${this.username}`); // Calcule a largura do texto "Gerado por: username"
      const xPosition = pageWidth - textWidth - marginRight; // Calcule a posição x para alinhar o texto à direita
      doc.text(`Gerado por: ${this.username}`, xPosition, 28);
      doc.text("Data e Hora da Impressão:", pageWidth - doc.getTextWidth("Data e Hora da Impressão:") - marginRight, 32);
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, pageWidth - doc.getTextWidth(`${UtilsPDF.formatDate(currentDate)} às ${hour}`) - marginRight, 36);

      if (data && data?.data_inicio && data?.data_fim) {
        doc.setFontSize(11);
        doc.text(`Período de: ${UtilsPDF.formatDate(data.data_inicio)} até: ${UtilsPDF.formatDate(data.data_fim)}`, 71, 32);
      }

      doc.setFontSize(12);
      doc.text(`Paciente: ${data && data.paciente ? data.paciente : ''}`, xFixedPosition, initialYPosition);

      yPosition = initialYPosition + lineHeight
      const dataNascimento = data && data.data_nascimento ? `Data Nascimento: ${UtilsPDF.formatDate(data.data_nascimento)}` : 'Data Nascimento: ';
      doc.text(dataNascimento, 200, initialYPosition, { align: "right" });

      doc.text(`Operadora: ${data && data.convenio ? data.convenio : ''}`, xFixedPosition, yPosition);
      doc.text(`Nº da Carteira: ${data && data.nr_carteirinha ? data.nr_carteirinha : ''}`, 200, yPosition, { align: "right", });

      yPosition += lineHeight
      doc.text(`Modalidade: ${data && data.modalidade ? data.modalidade : ''}`, xFixedPosition, yPosition);

      const maxWidth = 200;
      const spaceBetweenTexts = 10; // Espaço entre modalidadeText e CID

      const modalidadeText = `Modalidade: ${data && data.modalidade ? data.modalidade : ''}`;
      const modalidadeTextWidth = doc.getTextWidth(modalidadeText);

      if (data && data.cid && data.cid_descricao) {
        const cid = data.cid || '';
        const cidDescricao = data.cid_descricao || '';
        const fullText = `CID: ${cid} - ${cidDescricao}`;

        const renderTextLine = (doc, text, x, y, align = 'left') => {
          doc.setFont("helvetica", "normal");
          doc.text(text, x, y, { align: align });
        };

        const remainingWidth = maxWidth - modalidadeTextWidth - spaceBetweenTexts;
        const splittedText = doc.splitTextToSize(fullText, remainingWidth);

        splittedText.forEach((line, i) => {
          const xPos = i === 0 ? 200 : 10;
          const align = i === 0 ? 'right' : 'left';
          const yPos = yPosition + (lineHeight * i);
          renderTextLine(doc, line, xPos, yPos, align);
        });

        yPosition += (lineHeight * splittedText.length);

      } else {
        doc.setFont("helvetica", "normal");
        doc.text(`CID: `, 200, yPosition, { align: "right" });
      }

      if (doc.internal.getNumberOfPages() === 1) {
        yPosition = 75;
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text('Segue o orçamento detalhado conforme solicitação.', 8, yPosition);

        yPosition = 82;
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text('Ficamos no aguardo da autorização para darmos início aos serviços abaixo.', 8, yPosition);
      }
    },
     async generatePDF() {
      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      // const maxWidth = 170;
      // const xFixedPosition = 10;
      const pageWidth = 210;
      const maxHeight = 270;
      const lineHeight = 6;

      const data = this.budget[0];
      const startDate = new Date(data?.data_inicio);
      const endDate = new Date(data?.data_fim);
      const numberOfDays = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;
      const operadora = data && data.convenio ? data.convenio : '';

      let xPosition = 10;
      let yPosition = 93;
      this.contentAdded = false;


      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      const textWidth = doc.getTextWidth("Resumo do Orçamento");
      const xCenteredPosition = pageWidth / 2 - textWidth / 2;
      doc.text("Resumo do Orçamento", xCenteredPosition, yPosition);
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      yPosition += lineHeight * 2;

      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);
      
// Pacote
      const packageData = this.pacote.length > 0 ? this.pacote[0] : null;
      if (packageData) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Pacote(s)", xPosition, yPosition);
        yPosition += lineHeight;

        const nomePacote = (packageData?.pacote.nomecomercial && packageData?.pacote.nomecomercial.trim() !== "") ? `${packageData.pacote?.nome} - (${packageData?.pacote.nomecomercial})` : packageData.pacote?.nome;
        const row = {
          'Código': packageData.pacote?.codigo || '',
          'Pacote': nomePacote,
          'Qtd.': packageData.quantidade_pacote || '',
          'Valor Unitário': `${UtilsPDF.formatToBRL(packageData.valor_cobranca_pacote)}`,
          'Des %.': packageData.desconto_pacote || '--',
          'Acres %.': packageData.acrescimo_pacote || '--',
          'Valor Total': `${UtilsPDF.formatToBRL(packageData.valor_cobranca_final_pacote)}`
        };
        // console.log(row)
        const columns = ['Código', 'Pacote', 'Qtd.', 'Valor Unitário', 'Des %.', 'Acres %.', 'Valor Total'];
        const rows = [Object.values(row)];

        doc.autoTable({
          startY: yPosition,
          head: [columns],
          body: rows,
          styles: { lineWidth: 0.1 },
          margin: { left: 8, right: 8 }
        });

        const tableHeight = doc.lastAutoTable.finalY;
        yPosition = tableHeight + lineHeight;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);

        // console.log(UtilsPDF.formatToBRL(packageData.valor_cobranca_final_pacote))
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total do(s) Pacote(s): ${UtilsPDF.formatToBRL(packageData.valor_cobranca_final_pacote)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      } else {
        this.$toast.error("Nenhum dado de pacote encontrado (L-263):");
      }
// Procedimento
      const procediemntoOrcamento = this.prorrogacao;
      // console.log(procediemntoOrcamento)
      if (procediemntoOrcamento && procediemntoOrcamento.length > 0) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Procedimento(s)", xPosition, yPosition);

        yPosition += lineHeight;
        const table = this.prorrogacao.map(item => {
          const discount = item.desconto ? parseFloat(item.desconto) / 100 : 0;
          const addition = item.acrescimo ? parseFloat(item.acrescimo) / 100 : 0;
          const adjustedUnitValue = (item.cobranca && item.valor_cobranca)
            ? parseFloat(item.valor_cobranca) * (1 + addition - discount)
            : 0;
            const qtdRealizada = item.especial === true ? parseInt(item.qtdEspecial) : item.qtd;
            const valorTotal = adjustedUnitValue * qtdRealizada;
            const nomeProcedimento = (item?.nomecomercial && item.nomecomercial.trim() !== "") ? `${item.procedimento} - (${item.nomecomercial})` : item.procedimento;
            
            return {
              'Código': item.codigo,
              'Procedimento': nomeProcedimento,
              'Frequência': item.especial === true ? '' : item.frequencia,
              'Qtd.': qtdRealizada,
              'Valor Unitário': UtilsPDF.formatToBRL(parseFloat(item.valor_cobranca) || 0),
              'Des %.': item.cobranca === '0' ? '100.0' : (item.desconto || '--'),
              'Acres %.': (item.cobranca === '0' || item.desconto === '100') ? '--' : (item.acrescimo || '--'),
              'Valor Total Num': valorTotal,
              'Valor Total': UtilsPDF.formatToBRL(valorTotal)
            };
          });
          this.totalBudget = table.reduce((acc, item) => {
            return acc + (item['Valor Total Num'] || 0);  // Some os valores numéricos
          }, 0);
          // console.log(table)
          const columns = ['Código', 'Procedimento', 'Frequência', 'Qtd.', 'Valor Unitário', 'Des %.', 'Acres %.', 'Valor Total'];
          const rows = [];
          table.forEach(item => {
            const row = [];
            columns.forEach(column => {
              row.push(item[column]);
            });
            rows.push(row);
          });
          
        doc.autoTable({
          startY: yPosition,
          head: [columns],
          body: rows,
          styles: { lineWidth: 0.1 },
          margin: { left: 8, right: 8 },
          columnStyles: {
            3: { cellWidth: 12 },
            4: { halign: 'center',cellWidth: 25 },
            5: { cellWidth: 15 },
            6: { cellWidth: 15 },
            7: { cellWidth: 25 },
            // 8: { cellWidth: 30 },
            // 9: { cellWidth: 30 }
          },
        });

        yPosition = doc.lastAutoTable.finalY + lineHeight;

        // Cálculo do Valor Total dos Procedimentos
        const totalBudget = table.reduce((acc, item) => {
          const valorTotal = UtilsPDF.parseFormattedCurrency(item['Valor Total']) || 0;
          return acc + valorTotal;
        }, 0);

        // yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);
        yPosition += lineHeight * 2;
        yPosition = doc.lastAutoTable.finalY + lineHeight;
        // console.log(totalBudget)
        // console.log(UtilsPDF.formatToBRL(totalBudget))
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total do(s) Procedimento(s): ${totalBudget ? UtilsPDF.formatToBRL(totalBudget) : 'R$ 0,00'}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);

      }

// Taxa
      const taxas = this.taxa;
      // console.log(taxas)
      let totalFee = 0;
      if (taxas && taxas.length > 0) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Taxa(s)", xPosition, yPosition);

        yPosition += lineHeight;

        const columns = [
          'Código',
          'Taxa',
          'Qtd.',
          'Valor Unitário',
          'Des %.',
          'Acres %.',
          'Valor Total'
        ];
        const rows = [];

        taxas.forEach(taxa => {
          if (taxa.ativo) {
            const valorCobrancaTaxa = taxa?.valor_cobranca_taxa || 0;
            const valorCobrancaFinalTaxa = taxa?.valor_cobranca_final_taxa || 0;
            const nomeTaxa = (taxa?.taxa?.nomecomercial && taxa.taxa.nomecomercial.trim() !== "") ? `${taxa?.taxa?.taxa?.nome} - (${taxa?.taxa?.nomecomercial})` : taxa?.taxa?.taxa?.nome;

            const row = {
              'Código': taxa?.taxa?.codigo || '',
              'Taxa': nomeTaxa || '',
              'Qtd.': String(taxa?.quantidade_taxa || ''),
              'Valor Unitário': UtilsPDF.formatToBRL2(valorCobrancaTaxa),
              'Des %.': taxa?.desconto_taxa || '--',
              'Acres %.': taxa?.acrescimo_taxa || '--',
              'Valor Total': UtilsPDF.formatToBRL2(valorCobrancaFinalTaxa)
            };
            // console.log(row)
            rows.push(Object.values(row));

            totalFee += valorCobrancaFinalTaxa;
          }
        });

        try {
          doc.autoTable({
            startY: yPosition,
            head: [columns],
            body: rows,
            styles: { lineWidth: 0.1 },
            margin: { left: 8, right: 8 }
          });
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados (L-394):", error);
        }

        yPosition = doc.lastAutoTable.finalY + lineHeight;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);

        // console.log(UtilsPDF.formatToBRL2(totalFee))
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total da(s) Taxa(s): ${UtilsPDF.formatToBRL2(totalFee)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      } else {
        // this.$toast.error("Nenhum dado de taxa encontrado (L-404)");
        yPosition += lineHeight * 2;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);
      }

// MatMed
      const matmed = this.matmed;
      // console.log(taxas)
      let totalMatMed = 0;
      if (matmed && matmed.length > 0) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Material / Medicamento / Dieta", xPosition, yPosition);

        yPosition += lineHeight;

        const columns = [
          'Item',
          'Código',
          'Produto',
          'Qtd.',
          'Valor Unitário',
          'Des %.',
          'Acres %.',
          'Valor Total'
        ];
        const rows = [];

        matmed.forEach(matmed => {
          if (matmed.ativo) {
            const valorCobrancaMatMed = matmed?.valor_cobranca_matmed || 0;
            const valorCobrancaFinalTaxa = matmed?.valor_cobranca_final_matmed || 0;
            const nomematMed = matmed?.matmed.nome || null

            const row = {
              'Item': matmed?.aditivo_matmed ? 'Aditivo' : 'Orçamento',
              'Código': matmed?.codigo || '',
              'Taxa': nomematMed || '',
              'Qtd.': String(matmed?.quantidade_matmed || ''),
              'Valor Unitário': UtilsPDF.formatToBRL2(valorCobrancaMatMed),
              'Des %.': matmed?.desconto_matmed || '--',
              'Acres %.': matmed?.acrescimo_taxa || '--',
              'Valor Total': UtilsPDF.formatToBRL2(valorCobrancaFinalTaxa)
            };
            // console.log(row)
            rows.push(Object.values(row));

            totalMatMed += valorCobrancaFinalTaxa;
          }
        });

        try {
          doc.autoTable({
            startY: yPosition,
            head: [columns],
            body: rows,
            styles: { lineWidth: 0.1 },
            margin: { left: 8, right: 8 },
            columnStyles: {
              7: { cellWidth: 28 }
            },
          });
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados (L-394):", error);
        }

        yPosition = doc.lastAutoTable.finalY + lineHeight;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);

        // console.log(UtilsPDF.formatToBRL2(totalMatMed))
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total da(s) Taxa(s): ${UtilsPDF.formatToBRL2(totalMatMed)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      } else {
        // this.$toast.error("Nenhum dado de taxa encontrado (L-404)");
        yPosition += lineHeight * 2;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);
      }

// Equipamentto
      const equipamentos = this.equipamentos;
      let totalEquipment = 0;
      if (equipamentos && equipamentos.length > 0) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Equipamento(s)", xPosition, yPosition);

        yPosition += lineHeight;

        const columns = [
          'Código',
          'Equipamento',
          'Qtd.',
          'Valor Unitário',
          'Des %.',
          'Acres %.',
          'Valor Total'
        ];
        const rows = [];

        equipamentos.forEach(equipamento => {
          if (equipamento.ativo) {
            const valorCobranca = equipamento?.valor_cobranca_equipamento || 0;
            const valorCobrancaFinal = equipamento?.valor_cobranca_final_equipamento || 0;
            const nomeEquipamento = (equipamento?.equipamento?.nomecomercial && equipamento.equipamento.nomecomercial.trim() !== "") ? `${equipamento?.equipamento?.equipamento?.nome} - (${equipamento?.equipamento?.nomecomercial})` : equipamento?.equipamento?.equipamento?.nome;

            const row = {
              'Código': equipamento?.equipamento?.codigo || '',
              'Equipamento': nomeEquipamento || '',
              'Qtd.': String(equipamento?.quantidade_equipamento || ''),
              'Valor Unitário': UtilsPDF.formatToBRL2(valorCobranca),
              'Des %.': equipamento?.desconto_equipamento || '--',
              'Acres %.': equipamento?.acrescimo_equipamento || '--',
              'Valor Total': UtilsPDF.formatToBRL2(valorCobrancaFinal)
            };
            // console.log('valor Total por linha ', UtilsPDF.formatToBRL2(valorCobrancaFinal))
            rows.push(Object.values(row));

            totalEquipment +=  parseFloat(valorCobrancaFinal) || 0;
          }
        });
        try {
          doc.autoTable({
            startY: yPosition,
            head: [columns],
            body: rows,
            styles: { lineWidth: 0.1 },
            margin: { left: 8, right: 8 }
          });
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados (L-462):", error);
        }

        yPosition = doc.lastAutoTable.finalY + lineHeight;
        yPosition += lineHeight * 2;
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        // console.log(UtilsPDF.formatToBRL2(totalEquipment))
        if (totalEquipment > 0) {
          doc.text(`Valor Total do(s) Equipamento(s): ${UtilsPDF.formatToBRL2(totalEquipment)}`, 200, yPosition, { align: "right" });
        } else {
          doc.text(`Valor Total do(s) Equipamento(s): R$ 0,00`, 200, yPosition, { align: "right" });
        }
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);
      } else {
        // this.$toast.error("Nenhum dado de equipamento encontrado (L-472)");
      }



// inclusao da parte de observaçõa do conrato particular











      const pacoteValor = this.pacote ? this.pacote[0].valor_cobranca_final_pacote : 0;
      const totalOrcamento = this.totalBudget + pacoteValor + totalFee + totalEquipment + totalMatMed
      // console.log(totalOrcamento)
      const dailyAverage = totalOrcamento / numberOfDays;
      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, 260, 80, this.drawHeader);

      const text = 'do Orçamento'
      UtilsPDF.summary(doc, yPosition, lineHeight, dailyAverage, totalOrcamento, text)

      yPosition += lineHeight;
      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, 260, 80, this.drawHeader);

      yPosition += lineHeight + 2;
      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);

      UtilsPDF.addSignatureBlock(doc, operadora, lineHeight, xPosition, 245);

      UtilsPDF.addPageNumbers(doc);
      const data_inicio = data && data?.data_inicio;
      const data_fim = data && data?.data_fim;

      const period = data_inicio && data_fim ? `${UtilsPDF.formatDateToFilename(data_inicio)}_ate_${UtilsPDF.formatDateToFilename(data_fim)}` : '';
      const nomePaciente = data && data.paciente ? data.paciente : '';

      const filename = `Relatório_Orçamento_${nomePaciente}_${operadora}_Período_${period}.pdf`;
      // doc.save(filename);

      const pdfBuffer = doc.output('arraybuffer');
      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });
      const formData = new FormData();
      formData.append("file", pdfBlob, filename);
      formData.append("filename", filename);

      try {
        const { data: signedForm } = await api.post('multi_empresas/sign-pdf/', formData)
        const signedPdfUrl = signedForm.path
        this.download(signedPdfUrl, filename)
        this.$toast.success('O download do relatório foi realizado com sucesso!');
      } catch (error) {
        this.$toast.error("Error signing PDF (L-519):", error.response ? error.response.data : error.message);
      }
    },
    async getProrrogacao() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/relatorio-prorrogacao-resumo/?orcamento=${this.selectedBudgets[0]}`);
          this.prorrogacao = data.filter(item => item.aditivo === false);
          // console.log('getProrrogacao --> ', this.prorrogacao)
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados do Prorrogação (L-501).', error);
        }
      }
    },
    async getOrcamento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/relatorio-orcamento/?orcamento=${this.selectedBudgets[0]}`);
          this.budget = data;
          // console.log('this.budget', this.budget)
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados do Orçamento (L-511).', error);
        }
      }
    },
    async getPacote() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/pacotes/?orcamento=${this.selectedBudgets[0]}`);
          this.pacote = data.filter(item => item.ativo && (!item.aditivo || item.aditivo === null));
          // console.log('this.pacote', this.pacote)
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados do Pacote (L-522).', error);
        }
      }
    },
    async getTaxa() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/taxas/?orcamento=${this.selectedBudgets[0]}`);
          this.taxa = data.filter(item => item.ativo && (!item.aditivo_taxa || item.aditivo_taxa === null));
          // console.log('Taxas --> ', this.taxa)
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados de Taxa (L-533).', error);
        }
      }
    },
    async getEquipamentos() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/equipamentos/?orcamento=${this.selectedBudgets[0]}`);
          this.equipamentos = data.filter(item => item.ativo && (!item.aditivo_equipamento || item.aditivo_equipamento === null));
          // console.log('Equipamentos --> ', this.equipamentos)
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados do Equipamento (L-544).', error);
        }
      }
    },
    async getmatMed() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/matmeds/?orcamento=${this.selectedBudgets[0]}`);
          this.matmed = data.filter(item => item.ativo);
          // console.log('matmed --> ', this.matmed)
        } catch (error) {
          console.error('Erro ao buscar os dados do Equipamento (L-494):', error);
        }
      }
    },
  },
};
</script>
